.app-theme-dark {
  --body: rgb(255, 255, 255);
  --body-inverse: rgb(0, 0, 0);
  --primary: rgb(130, 147, 160);
  --primary-lit: rgb(204, 226, 242);
  --accent: rgb(0, 179, 170);
  --accent-lit: rgb(4, 214, 203);
  --accent-hint: rgb(0, 88, 80);
  --accent-strong: rgb(3, 164, 224);
  --accent-strong-lit: rgb(26, 193, 255);
  --accent-strong-hint: rgb(116, 135, 146);
  --subtle: rgb(116, 116, 116);
  --hint: rgb(71, 75, 79);
  --font-family: 'Sarabun', -apple-system, BlinkMacSystemFont, 'Segoe Pro',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --font-header-family: 'Sarabun', -apple-system, BlinkMacSystemFont, 'Segoe Pro',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --bg: rgb(0, 0, 0);
  --bg-hint: rgb(16, 19, 20);
  --bg-hint-transparent: rgb(16 19 20 / 75%);
  --bg-lit: rgb(22, 26, 27);
  --bg-translucent: rgba(0, 0, 0, 0.9);
  --bg-transparent: rgb(0, 0, 0, 0.33);
  --bg-1: rgb(22, 26, 27);
  --bg-1-lit: rgb(38, 44, 45);
  --bg-1-translucent: rgb(27, 33, 35, 0.8);
  --bg-1-translucent-lit: rgb(41, 51, 53, 0.8);
  --bg-1-translucent-subtle: rgb(55, 63, 66, 0.2);
  --bg-2: rgb(36, 40, 41);
  --bg-2-lit: rgb(42, 48, 49);
  --bg-2-translucent: rgba(41, 47, 49, 0.5);
  --bg-2-translucent-lit: rgba(47, 55, 58, 0.5);
  --bg-2-translucent-subtle: rgb(36, 40, 41, 0.9);
  --bg-3: rgb(55, 64, 66);
  --bg-3-lit: rgb(61, 70, 72);
  --bg-accent: rgb(0, 51, 48);
  --bg-accent-subtle: rgb(12, 27, 26);
  --bg-accent-hint: rgb(6, 16, 16);
  --bg-accent-translucent: rgb(31, 70, 66, 0.55);
  --bg-warn: rgb(38, 11, 6);
  --warn: rgb(241, 70, 47);
  --warn-lit: rgb(255, 109, 90);
  --warn-strong: rgb(255, 140, 105, 0.9);
  --caution: rgb(252, 214, 88);

  --border: rgb(35, 43, 45);
  --border-translucent-strong: rgb(255, 255, 255, 0.12);
  --border-translucent: rgb(255, 255, 255, 0.08);
  --border-translucent-subtle: rgb(255, 255, 255, 0.04);
  --border-1: rgb(37, 44, 45);
  --border-1-lit: rgb(57, 70, 74);
  --border-2: rgb(49, 58, 59);
  --border-2-lit: rgb(66, 80, 82);
  --border-3: rgb(66, 80, 82);

  --mdc-dialog-subhead-color: #FFFFFF;
  --mdc-dialog-supporting-text-color: #FFFFFF;

  --mdc-dialog-container-color: black;
  --mdc-chip-elevated-container-color: #616161;
  --mdc-icon-button-disabled-icon-color: rgb(71, 75, 79);
  --mdc-theme-primary: rgb(130, 147, 160);
  --mdc-theme-accent: rgb(0, 179, 170);
  --mdc-radio-unselected-icon-color: #FFFFFF70;
  --mdc-radio-selected-icon-color: rgb(0, 179, 170);
  --mdc-plain-tooltip-container-color: var(--bg-2);
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-tooltip-word-break: normal;
  --mdc-text-button-label-text-color: white;
  --mdc-checkbox-selected-checkmark-color: rgb(0, 0, 0);
  --mdc-checkbox-disabled-selected-icon-color: rgb(130, 147, 160);
  --mdc-checkbox-disabled-unselected-icon-color: var(--subtle);
  --mdc-checkbox-unselected-icon-color: var(--primary);
  --mdc-checkbox-unchecked-color: var(--subtle);
  --mdc-checkbox-checked-color: var(--accent);
  --mdc-checkbox-disabled-color: var(--border-2);
  --mdc-checkbox-unchecked-hover-color: var(--subtle);
  --mdc-checkbox-checked-hover-color: var(--accent-lit);
  --mdc-checkbox-indeterminate-color: var(--accent-strong);
  --mdc-checkbox-ripple-color: rgba(var(--rgb), 0.1);
  --mdc-checkbox-unselected-focus-icon-color: var(--primary);
  --mdc-text-button-container-height: 1.5rem;

  --mdc-dialog-subhead-font: var(--font-family)
  --mdc-dialog-subhead-line-height: 24px;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: bold;
  --mdc-dialog-subhead-tracking: normal;
  --mat-mdc-radio-ripple-color: #ffffff85;
  --mdc-linear-progress-active-indicator-color: rgb(0, 179, 170);
  --mdc-switch-unselected-handle-color: #BDBDBD;
  --mdc-switch-unselected-hover-track-color: rgba(255, 255, 255, 0.5);
  --mdc-checkbox-selected-icon-color: var(--accent);
  --mdc-linear-progress-active-indicator-color: rgb(0, 179, 170);
  --mdc-switch-unselected-handle-color: #BDBDBD;
  --mdc-switch-unselected-hover-track-color: rgba(255, 255, 255, 0.5);
  --mdc-dialog-supporting-text-font: var(--font-family);
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: normal;
  --mdc-dialog-supporting-text-tracking: .5px;
  --mdc-slider-active-track-color: #00b3aa70;
  --mdc-slider-handle-color: var(--accent-strong-lit);
  --mdc-slider-inactive-track-color: var(--accent);
  --mdc-switch-disabled-selected-track-color: #00b3aa57;
  --mdc-switch-disabled-selected-handle-color: #00b3aa80;
  --mdc-list-list-item-label-text-color: var(--primary-lit);
  --mdc-snackbar-container-color: var(--bg-3);
  --mdc-icon-button-disabled-icon-color: var(--bg-3);
  --mdc-radio-selected-hover-icon-color:  rgb(0, 179, 170);
  --mdc-radio-selected-pressed-icon-color:  rgb(0, 179, 170);
  .app-elevation-1 {
    --primary: rgb(130, 147, 160);
    --subtle: rgb(101, 114, 125);
    --bg-accent: rgb(20, 51, 50);
  }

  .app-palette-body {
    --rgb: 204, 225, 226;
  }

  .app-palette-body-strong {
    --rgb: 255, 255, 255;
    --alpha: 1;
    --bg-alpha: 0.1;
    --bg-2-alpha: 0.15;
  }

  .app-palette-11,
  .app-palette-gray {
    --rgb: 99, 110, 119;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-accent-strong-hint {
    --rgb: 137, 188, 199;
    --rgb-dim: 137, 188, 199;
    --alpha: 0.9;
    --bg-alpha: 0.1;
    --bg-2-alpha: 0.15;
  }

  .app-palette-primary {
    --rgb: 130, 147, 160;
    --alpha: 1;
    --bg-alpha: 0.1;
    --bg-2-alpha: 0.15;
  }

  .app-palette-secondary {
    --alpha: 0.5;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.1;
  }

  .app-palette-subtle {
    --alpha: 0.6;
  }

  .mat-calendar {
    background-color: var(--bg-1);
    color: var(--text);
    border-radius: var(--border-radius);
    border: 1px solid var(--border-1);
  }

  input[type="radio"]:hover {
    background-color: #000 !important;
    border-color: #fff !important;
    color: #fff !important;
    cursor: pointer;
    box-shadow: var(--shadow) !important;
  }
}
