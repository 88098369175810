/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Sarabun Regular'), local('Sarabun-Regular'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVjJx26TKEr37c9aAFJn3YO5gjupg.woff2)
      format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Sarabun Regular'), local('Sarabun-Regular'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVjJx26TKEr37c9aBpJn3YO5gjupg.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9,
    U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Sarabun Regular'), local('Sarabun-Regular'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVjJx26TKEr37c9aBtJn3YO5gjupg.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Sarabun Regular'), local('Sarabun-Regular'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVjJx26TKEr37c9aBVJn3YO5gg.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Sarabun Medium'), local('Sarabun-Medium'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YOZqik8s6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Sarabun Medium'), local('Sarabun-Medium'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YOZqilQs6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9,
    U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Sarabun Medium'), local('Sarabun-Medium'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YOZqilUs6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Sarabun Medium'), local('Sarabun-Medium'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YOZqilss6yLUrwA.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Sarabun SemiBold'), local('Sarabun-SemiBold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YMptik8s6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Sarabun SemiBold'), local('Sarabun-SemiBold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YMptilQs6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9,
    U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Sarabun SemiBold'), local('Sarabun-SemiBold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YMptilUs6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Sarabun SemiBold'), local('Sarabun-SemiBold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YMptilss6yLUrwA.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Sarabun Bold'), local('Sarabun-Bold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YK5sik8s6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Sarabun Bold'), local('Sarabun-Bold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YK5silQs6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9,
    U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Sarabun Bold'), local('Sarabun-Bold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YK5silUs6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Sarabun Bold'), local('Sarabun-Bold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YK5silss6yLUrwA.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Sarabun ExtraBold'), local('Sarabun-ExtraBold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YLJvik8s6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Sarabun ExtraBold'), local('Sarabun-ExtraBold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YLJvilQs6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9,
    U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Sarabun ExtraBold'), local('Sarabun-ExtraBold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YLJvilUs6yLUrwB0lw.woff2)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Sarabun ExtraBold'), local('Sarabun-ExtraBold'),
    url(https://fonts.gstatic.com/s/sarabun/v7/DtVmJx26TKEr37c9YLJvilss6yLUrwA.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
