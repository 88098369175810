:root,
html .app-theme-light {
  --body: rgb(0, 0, 0);
  --body-inverse: rgb(255, 255, 255);
  --primary: rgb(91, 103, 112);
  --primary-lit: rgb(46, 66, 82);
  --accent: rgb(0, 179, 170);
  --accent-lit: rgb(3, 142, 135);
  --accent-hint: rgb(181, 235, 231);
  --accent-strong: rgb(0, 174, 239);
  --accent-strong-lit: rgb(0, 148, 204);
  --accent-strong-hint: rgb(98, 199, 236);
  --subtle: rgb(164, 172, 176);
  --hint: rgb(183, 190, 192);
  --warn: rgb(241, 70, 47);
  --warn-lit: rgb(255, 109, 90);
  --warn-strong: rgb(255, 140, 105, 0.9);
  --caution: rgb(252, 214, 88);

  --bg: rgb(255, 255, 255);
  --bg-hint: rgb(235 239 240);
  --bg-hint-transparent: rgb(235 239 240 / 75%);
  --bg-lit: rgb(226, 236, 239);
  --bg-translucent: rgba(255, 255, 255, 0.9);
  --bg-transparent: rgb(255, 255, 255, 0.33);
  --bg-1: rgb(238, 244, 246);
  --bg-1-lit: rgb(225, 233, 236);
  --bg-1-translucent: rgb(238, 244, 246, 0.8);
  --bg-1-translucent-lit: rgb(218, 224, 226, 0.8);
  --bg-1-translucent-subtle: rgb(139, 157, 163, 0.2);
  --bg-2: rgb(209, 221, 223);
  --bg-2-lit: rgb(188, 201, 203);
  --bg-2-translucent: rgba(188, 201, 203, 0.5);
  --bg-2-translucent-lit: rgba(188, 193, 194, 0.9);
  --bg-2-translucent-subtle: rgba(209, 221, 223, 0.9);
  --bg-3: rgb(188, 201, 203);
  --bg-3-lit: rgb(180, 193, 195);
  --bg-accent: rgb(185, 247, 245);
  --bg-accent-subtle: rgb(198, 249, 249);
  --bg-accent-hint: rgb(228, 255, 255);
  --bg-accent-translucent: rgb(209, 251, 250, 0.55);
  --bg-warn: rgb(232, 208, 204);

  --border: rgb(212, 217, 217);
  --border-translucent-strong: rgb(0, 0, 0, 0.12);
  --border-translucent: rgb(0, 0, 0, 0.08);
  --border-translucent-subtle: rgb(0, 0, 0, 0.04);
  --border-1: rgb(224, 232, 232);
  --border-1-lit: rgb(170, 181, 181);
  --border-2: rgb(205, 211, 211);
  --border-2-lit: rgb(186, 193, 193);
  --border-3: rgb(186, 193, 193);
  --mdc-radio-selected-hover-icon-color:  rgb(0, 179, 170);
  --mdc-radio-selected-pressed-icon-color:  rgb(0, 179, 170);
  --border-radius: 4px;
  --border-radius-big: 8px;
  --border-radius-huge: 16px;
  --mdc-list-list-item-label-text-color: var(--primary-lit);

  --font-family: 'Sarabun', -apple-system, BlinkMacSystemFont, 'Segoe Pro',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --font-header-family: 'Sarabun', -apple-system, BlinkMacSystemFont, 'Segoe Pro',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  --font-weight: 400;
  --font-link-weight: 600;
  --font-header-weight: 800;

  --font-size: 16px;
  --font-size-mini: 0.625rem;
  --font-size-tiny: 0.75rem;
  --font-size-smaller: 0.825rem;
  --font-size-small: 0.875rem;
  --font-size-big: 1.25rem;
  --font-size-huge: 1.5rem;
  --font-size-gigantic: 2rem;
  --font-size-massive: 3rem;
  --font-size-header: 20px;

  --inset-tiny: 0.25rem;
  --inset-small: 0.5rem;
  --inset: 1rem;
  --inset-big: 1.5rem;
  --inset-huge: 2.25rem;

  --stack-tiny: 0.25rem;
  --stack-small: 0.5rem;
  --stack: 0.75rem;
  --stack-big: 1.25rem;
  --stack-huge: 2rem;

  --shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.1), 0 2px 4px 0 rgb(0, 0, 0, 0.05),
    0 5px 10px 0 rgb(0, 0, 0, 0.05);
  --shadow-big: 0 1px 1px rgb(0, 0, 0, 0.11), 0 2px 2px rgb(0, 0, 0, 0.11),
    0 4px 4px rgb(0, 0, 0, 0.11), 0 8px 8px rgb(0, 0, 0, 0.11),
    0 16px 16px rgb(0, 0, 0, 0.11), 0 32px 32px rgb(0, 0, 0, 0.11);

  // Template Dimensions
  --header-height: 100px;
  --footer-height: 24px;
  --header-height-small: 76px;
  --header-height-xsmall: 50px;
  --client-panel-width: 220px;
  --client-panel-width-small: 180px;
  --program-panel-width: 240px;
  --program-panel-width-small: 180px;
  --layer-panel-width: 220px;
  --layer-panel-width-large: 440px;
  --layer-panel-width-xlarge: 680px;
  --layer-panel-width-small: 180px;
  --layer-panel-width-xsmall: 140px;
  --props-panel-width-large: 325px;
  --props-panel-width: 281px;
  --props-panel-width-small: 192px;
  --table-row-height: 30px;
  --compare-sidebar-width: 270px;
  --metric-input-width: 95px;
  --incumbent-panel-width: 125px;
  --incumbent-panel-width-small: 60px;
  --facultive-panel-width: 420px;
  --facultive-panel-width-small: 258px;

  .mat-mdc-paginator .paginator {
    color: black !important;
  }

  /* Decrease sizes on small screen */
  @media screen and (max-width: 1240px) {
    --program-panel-width: 200px;
    --compare-sidebar-width: 270px;
  }

  // Palette

  .app-tower-layer {
    fill: rgb(var(--rgb), 0.8);
    stroke: rgb(var(--rgb), 0.9);
  }

  .app-tower-layer.app-tower-layer-override-test {
    fill: rgb(var(--rgb), 1);
    stroke: rgb(var(--rgb), 0.9);
  }

  .app-tower-layer.app-tower-layer-override {
    stroke: #808080;
  }

  .app-tower-shared-layer-group-1 {
    mask: url(#mask-diagonal-stripe);
  }

  .app-tower-shared-layer-group-2 {
    mask: url(#mask-vertical-stripe);
  }

  .app-tower-shared-layer-group-3 {
    mask: url(#mask-cross-hatch);
  }

  .app-tower-shared-layer-group-4 {
    mask: url(#mask-dot-small);
  }

  .app-tower-shared-layer-group-5 {
    mask: url(#mask-blocks);
  }

  .app-tower-shared-layer-group-6 {
    mask: url(#mask-square);
  }

  .app-tower-shared-layer-group-7 {
    mask: url(#mask-two-triangles);
  }

  .app-tower-shared-layer-group-8 {
    mask: url(#mask-triangle);
  }

  .app-tower-shared-layer-group-9 {
    mask: url(#mask-curvy);
  }

  .app-tower-shared-layer-group-10 {
    mask: url(#mask-semi-circle);
  }

  .app-palette-1,
  .app-palette-orange,
  .app-palette-cat_qs,
  .app-palette-pmi_qs,
  .app-palette-noncat_qs,
  .app-palette-ahl_qs,
  .app-palette-other_qs {
    --rgb: 246, 138, 51;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-orange-subtle {
    @extend .app-palette-orange;
    --alpha: 0.5;
  }

  .app-palette-2,
  .app-palette-blue,
  .app-palette-cat_xl,
  .app-palette-pmi_agg_xl,
  .app-palette-srt_agg_xl,
  .app-palette-noncat_xl,
  .app-palette-noncat_indxl,
  .app-palette-ahl_xl,
  .app-palette-other_xl {
    --rgb: 0, 174, 239;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-3,
  .app-palette-green,
  .app-palette-pmi_iln,
  .app-palette-cat_cascading,
  .app-palette-cat_ca {
    --rgb: 1, 201, 109;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-4,
  .app-palette-pink,
  .app-palette-top_and_drop,
  .app-palette-drop,
  .app-palette-cat_td {
    --rgb: 239, 83, 153;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-5,
  .app-palette-body,
  .app-palette-noncat_agg_xl,
  .app-palette-other_ag,
  .app-palette-cat_agg_xl,
  .app-palette-cat_ag,
  .app-palette-noncat_ag,
  .app-palette-ahl_ag {
    --rgb: 204, 225, 226;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-6,
  .app-palette-yellow,
  .app-palette-cat_fhcf,
  .app-palette-noncat_fhcf {
    --rgb: 252, 214, 88;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-7,
  .app-palette-magenta,
  .app-palette-noncat_risk,
  .app-palette-other_rxl {
    --rgb: 189, 118, 255;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-8,
  .app-palette-red {
    --rgb: 241, 70, 47;
    --rgb-dim: 105, 20, 9;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-9,
  .app-palette-salmon {
    --rgb: 242, 182, 164;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-10,
  .app-palette-cat_ilw,
  .app-palette-cat_ilw_pro_rata,
  .app-palette-cat_ilw_ag,
  .app-palette-cat_ilw_bin,
  .app-palette-teal {
    --rgb: 0, 179, 170;
    --rgb-dim: 0, 51, 48;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-11,
  .app-palette-gray {
    --rgb: 183, 190, 192;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-gray-subtle {
    @extend .app-palette-gray;
    --alpha: 0.5;
  }

  .app-palette-12,
  .app-palette-purple,
  .app-palette-noncat_swing,
  .app-palette-ahl_swing {
    --rgb: 92, 80, 255;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-13,
  .app-palette-lime,
  .app-palette-cat_multisection,
  .app-palette-noncat_multisection {
    --rgb: 188, 240, 99;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.4;
  }

  .app-palette-14,
  .app-palette-indigo {
    --rgb: 70, 130, 180;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-15,
  .app-palette-cyan {
    --rgb: 136, 244, 246;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-16,
  .app-palette-taupe {
    --rgb: 184, 161, 189;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-17,
  .app-palette-brown {
    --rgb: 140, 86, 75;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-18,
  .app-palette-mauve {
    --rgb: 255, 166, 246;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-19,
  .app-palette-mint {
    --rgb: 148, 255, 181;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-20,
  .app-palette-gold {
    --rgb: 183, 153, 102;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-21,
  .app-palette-olive {
    --rgb: 116, 167, 113;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-22,
  .app-palette-pewter {
    --rgb: 124, 170, 193;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-23,
  .app-palette-lightgray {
    --rgb: 211, 211, 211;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-body {
    --rgb: 72, 84, 84;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-body-strong {
    --rgb: 0, 0, 0;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-accent-strong-hint {
    --rgb: 181, 235, 231;
    --rgb-dim: 181, 235, 231;
    --alpha: 0.9;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-warn-strong {
    --rgb: 255, 140, 105;
    --rgb-dim: 255, 140, 105;
    --alpha: 0.9;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-primary {
    --rgb: 91, 103, 112;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-secondary {
    --alpha: 0.5;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.1;
  }

  .app-palette-subtle {
    --alpha: 0.6;
  }

  .app-palette-layer {
    --rgb: 67, 158, 221;
    --alpha: 1;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }

  .app-palette-transparent {
    --rgb: 183, 190, 192;
    --alpha: 0.3;
    --bg-alpha: 0.05;
    --bg-2-alpha: 0.15;
  }
}
