.annotation {
  &.show-bg {
    .annotation-note-bg {
      fill: gray;
      fill-opacity: 0.9;
    }
  }
}

.app-tower-layer-3d-loss {
  fill: rgba(var(--rgb), 0.1);
}

.app-tower-layer-3d-stepdown {
  fill: rgba(var(--rgb), 0.5);
}
