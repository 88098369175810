.tick,
.app-tower-box-label {
  user-select: none;
}

.app-tower-box-label {
  font-size: var(--font-size-small);
  font-weight: var(--font-link-weight);
  font-family: var(--font-family);
  opacity: 0.7;
}

.app-tower-watermark {
  font-size: var(--inset);
  font-weight: var(--font-link-weight);
  font-family: var(--font-family);
  opacity: 0.5;
}

.app-tower-x-axis .tick,
.app-tower-y-axis .tick {
  font-size: var(--font-size-tiny);
}
