.app-rag-100 {
  --rgb: 1, 201, 109;
  --color: rgb(1, 201, 109);
  --rank-rgb: #298a50;
  --rank-color: #fff;
}

.app-rag-95 {
  --rgb: 68, 204, 101;
  --color: rgb(68, 204, 101);
}

.app-rag-90 {
  --rgb: 99, 206, 94;
  --color: rgb(99, 206, 94);
}

.app-rag-85 {
  --rgb: 123, 208, 88;
  --color: rgb(123, 208, 88);
}

.app-rag-80 {
  --rgb: 145, 210, 83;
  --color: rgb(145, 210, 83);
}

.app-rag-75 {
  --rgb: 165, 211, 79;
  --color: rgb(165, 211, 79);
}

.app-rag-70 {
  --rgb: 183, 212, 77;
  --color: rgb(183, 212, 77);
}

.app-rag-65 {
  --rgb: 202, 213, 77;
  --color: rgb(202, 213, 77);
}

.app-rag-60 {
  --rgb: 219, 214, 79;
  --color: rgb(219, 214, 79);
}

.app-rag-55 {
  --rgb: 236, 214, 82;
  --color: rgb(236, 214, 82);
}

.app-rag-50 {
  --rgb: 252, 214, 88;
  --color: rgb(252, 214, 88);
  --rank-rgb: #ffd633;
  --rank-color: #080808;
}

.app-rag-45 {
  --rgb: 253, 202, 77;
  --color: rgb(253, 202, 77);
}

.app-rag-40 {
  --rgb: 253, 189, 67;
  --color: rgb(253, 189, 67);
}

.app-rag-35 {
  --rgb: 253, 176, 59;
  --color: rgb(253, 176, 59);
}

.app-rag-30 {
  --rgb: 253, 163, 53;
  --color: rgb(253, 163, 53);
}

.app-rag-25 {
  --rgb: 252, 149, 48;
  --color: rgb(252, 149, 48);
}

.app-rag-20 {
  --rgb: 251, 135, 46;
  --color: rgb(251, 135, 46);
}

.app-rag-15 {
  --rgb: 249, 121, 45;
  --color: rgb(249, 121, 45);
}

.app-rag-10 {
  --rgb: 247, 106, 46;
  --color: rgb(247, 106, 46);
}

.app-rag-5 {
  --rgb: 244, 90, 47;
  --color: rgb(244, 90, 47);
}

.app-rag-0 {
  --rgb: 241, 72, 50;
  --color: rgb(241, 72, 50);
  --rank-rgb: #d6043c;
  --rank-color: #fff;
}

.app-rag-alpha {
  --alpha: 1;
}
