// README Before changing styles here
// This is a work in progress for styling syncfusion to better match the "SAGE" themes.
//
// 1. Best practice is to use the varible names as from app-theme-dark and app-theme-light to best match the
// Syncfusion componenets
// 2. Many of the variables point to other variables within this css class
//    Example:
//       --color-sf-content-bg-color-pressed: var(--color-sf-content-bg-color-alt2);
//    If you are chaginging please change the variable at the top level before going to the lower variable
//    Example:
//      I want to make --color-sf-content-bg-color-pressed a brighter color to better match the SAGE theme
//      --color-sf-content-bg-color-pressed: var(--color-sf-content-bg-color-alt3);
//                                                                              ^Change
//      Keeping consistent styles here will make it easier to work with in the future.

.syncfusion-overwrites{
    --color-sf-black: var(--body);
    --color-sf-white: var(--body-inverse);
    --color-sf-content-bg-color: var(--bg-1);
    --color-sf-content-bg-color-alt1: var(--bg);
    --color-sf-content-bg-color-alt2: var(--bg-hint);
    --color-sf-content-bg-color-alt3: var(--bg-lit);
    --color-sf-content-bg-color-alt4: var(--bg-2);
    --color-sf-content-bg-color-alt5: var(--bg);
    --color-sf-content-bg-color-alt6: var(--bg);
    --color-sf-content-bg-color-hover: var(--color-sf-content-bg-color-alt3);
    --color-sf-content-bg-color-pressed: var(--color-sf-content-bg-color-alt2);
    --color-sf-content-bg-color-focus: var(--color-sf-content-bg-color-hover);
    --color-sf-content-bg-color-selected: #2e2e2e;
    --color-sf-content-bg-color-dragged: #08233b;
    --color-sf-content-bg-color-disabled: #141414;
    --color-sf-flyout-bg-color: var(--color-sf-content-bg-color);
    --color-sf-flyout-bg-color-hover: var(--bg-lit);
    --color-sf-flyout-bg-color-pressed: var(--color-sf-black);
    --color-sf-flyout-bg-color-selected: var(--bg-lit);
    --color-sf-flyout-bg-color-focus: var(--color-sf-flyout-bg-color-hover);
    --color-sf-overlay-bg-color: rgba($black, .4);
    --color-sf-table-bg-color-hover: var(--bg-lit);
    --color-sf-table-bg-color-pressed: var(--bg-lit);
    --color-sf-table-bg-color-selected: var(--bg-accent);
    --color-sf-content-text-color: var(--body);
    --color-sf-content-text-color-alt1: #d6d6d6;
    --color-sf-content-text-color-alt2: #adadad;
    --color-sf-content-text-color-alt3: #999;
    --color-sf-content-text-color-hover: var(--color-sf-content-text-color);
    --color-sf-content-text-color-pressed: var(--color-sf-content-text-color);
    --color-sf-content-text-color-focus: var(--color-sf-content-text-colo);
    --color-sf-content-text-color-selected: var(--color-sf-content-text-color);
    --color-sf-content-text-color-dragged: var(--color-sf-content-text-color);
    --color-sf-content-text-color-disabled: var(--primary);
    --color-sf-placeholder-text-color: var(--color-sf-content-text-color-alt3);
    --color-sf-flyout-text-color: var(--color-sf-content-text-color);
    --color-sf-flyout-text-color-hover: var(--color-sf-content-text-color);
    --color-sf-flyout-text-color-pressed: var(--color-sf-content-text-color);
    --color-sf-flyout-text-color-selected: var(--color-sf-content-text-color);
    --color-sf-flyout-text-color-focus: var(--color-sf-content-text-color);
    --color-sf-flyout-text-color-disabled: var(--color-sf-content-text-color-disabled);
    --color-sf-table-text-color-hover: var(--color-sf-content-text-color);
    --color-sf-table-text-color-pressed: var(--color-sf-content-text-color);
    --color-sf-table-text-color-selected: var(--color-sf-content-text-color);
    --color-sf-icon-color: #d6d6d6;
    --color-sf-icon-color-hover: var(--color-sf-content-text-color);
    --color-sf-icon-color-hover-alt: var(--color-sf-content-text-color);
    --color-sf-icon-color-pressed: var(--color-sf-content-text-color);
    --color-sf-icon-color-disabled: var(--color-sf-content-text-color-disabled);
    --color-sf-border-light: var(--border-3);
    --color-sf-border: var(--border-2);
    --color-sf-border-alt: var(--border-1-lit);
    --color-sf-border-dark: var(--border-1);
    --color-sf-border-hover: var(--border-2-lit);
    --color-sf-border-pressed: var(--subtle);
    --color-sf-border-focus: var(--color-sf-border);
    --color-sf-border-selected: #6b6b6b;
    --color-sf-border-dragged: var(--color-sf-primary);
    --color-sf-border-disabled: #424242;
    --color-sf-border-warning: #bc4b09;
    --color-sf-border-error: #dc626d;
    --color-sf-border-success: #9fd89f;
    --color-sf-border-info: #18a4c4;
    --color-sf-spreadsheet-gridline: #e6e6e6;
    --color-sf-flyout-border: #525252;
    --color-sf-primary: var(--accent);
    --color-sf-primary-text-color: var(--body-inverse);
    --color-sf-primary-light: var(--accent);
    --color-sf-primary-lighter: var(--accent-lit);
    --color-sf-primary-dark: var(--accent-hint);
    --color-sf-primary-darker: var(--accent-hint);
    --color-sf-success: #107c10;
    --color-sf-info: #0099bc;
    --color-sf-warning: #faa06b;
    --color-sf-danger: #dc626d;
    --color-sf-success-light: #094509;
    --color-sf-info-light: #005669;
    --color-sf-warning-light: #4a1e04;
    --color-sf-danger-light: #6e0811;
    --color-sf-success-dark: #c9eac9;
    --color-sf-info-dark: #cdedf4;
    --color-sf-warning-dark: #fee5d7;
    --color-sf-danger-dark: #fee5d7;
    --color-sf-success-light-alt: #052505;
    --color-sf-info-light-alt: #002e38;
    --color-sf-warning-light-alt: #4a1e04;
    --color-sf-danger-light-alt: #3b0509;
    --color-sf-badge-warning: #fde300;
    --color-sf-badge-warning-text: #fde300;
    --color-sf-primary-label-color: #479ef5;
    --color-sf-primary-cell-selection: #ebf3fc;
    --color-sf-primary-bg-color: var(--color-sf-primary);
    --color-sf-primary-border-color: var(--color-sf-primary);
    --color-sf-primary-text: var(--color-sf-primary-text-color);
    --color-sf-primary-bg-color-hover: var(--accent-hint);
    --color-sf-primary-border-color-hover: var(--color-sf-primary-bg-color-hover);
    --color-sf-primary-text-hover: var(--color-sf-primary-text-color);
    --color-sf-primary-bg-color-pressed: var(--accent-hint);
    --color-sf-primary-border-color-pressed: var(--color-sf-primary-bg-color-pressed);
    --color-sf-primary-text-pressed: var(--color-sf-primary-text-color);
    --color-sf-primary-bg-color-focus: var(--color-sf-primary-bg-color-hover);
    --color-sf-primary-border-color-focus: var(--color-sf-primary-bg-color-focus);
    --color-sf-primary-text-focus: var(--color-sf-primary-text-color);
    --color-sf-primary-bg-color-disabled: #141414;
    --color-sf-primary-border-color-disabled: var(--color-sf-primary-bg-color-disabled);
    --color-sf-primary-text-disabled: var(--color-sf-content-text-color-disabled);
    --color-sf-primary-bg-color-selected: #0f548c;
    --color-sf-primary-border-color-selected: var(--color-sf-primary-bg-color-selected);
    --color-sf-primary-text-selected: var(--color-sf-primary-text-color);
    --color-sf-secondary-bg-color: #292929;
    --color-sf-secondary-border-color: var(--color-sf-border);
    --color-sf-secondary-text-color: var(--color-sf-content-text-color);
    --color-sf-secondary-bg-color-hover: #3d3d3d;
    --color-sf-secondary-border-color-hover: #757575;
    --color-sf-secondary-text-color-hover: var(--color-sf-content-text-color);
    --color-sf-secondary-bg-color-pressed: var(--color-sf-black);
    --color-sf-secondary-border-color-pressed: #6b6b6b;
    --color-sf-secondary-text-color-pressed: var(--color-sf-content-text-color);
    --color-sf-secondary-bg-color-focus: #383838;
    --color-sf-secondary-border-color-focus: #fff;
    --color-sf-secondary-text-color-focus: var(--color-sf-content-text-color);
    --color-sf-secondary-bg-color-disabled: -var(-color-sf-primary-bg-color-disabled);
    --color-sf-secondary-border-color-disabled: var(--color-sf-secondary-bg-color-disabled);
    --color-sf-secondary-text-color-disabled: var(--color-sf-content-text-color-disabled);
    --color-sf-secondary-bg-color-selected: #383838;
    --color-sf-secondary-border-color-selected: #707070;
    --color-sf-secondary-text-color-selected: var(--color-sf-content-text-color);
    --color-sf-success-bg-color: #218c21;
    --color-sf-success-border-color: var(--color-sf-success-bg-color);
    --color-sf-success-text: var(--color-sf-primary-text-color);
    --color-sf-success-bg-color-hover: #0e700e;
    --color-sf-success-border-color-hover: var(--color-sf-success-bg-color-hover);
    --color-sf-success-text-hover: var(--color-sf-primary-text-color);
    --color-sf-success-bg-color-pressed: #052505;
    --color-sf-success-border-color-pressed: var(--color-sf-success-bg-color-pressed);
    --color-sf-success-text-pressed: var(--color-sf-primary-text-color);
    --color-sf-success-bg-color-focus: var(--color-sf-success-bg-color);
    --color-sf-success-border-color-focus: var(--color-sf-success-bg-color-focus);
    --color-sf-success-text-focus: var(--color-sf-primary-text-color);
    --color-sf-success-bg-color-disabled: var(--color-sf-primary-bg-color-disabled);
    --color-sf-success-border-color-disabled: var(--color-sf-success-bg-color-disabled);
    --color-sf-success-text-disabled: var(--color-sf-primary-text-disabled);
    --color-sf-success-bg-color-selected: #094509;
    --color-sf-success-border-color-selected: var(--color-sf-success-bg-color-selected);
    --color-sf-success-text-selected: var(--color-sf-primary-text-color);
    --color-sf-warning-bg-color: #f87528;
    --color-sf-warning-border-color: var(--color-sf-warning-bg-color);
    --color-sf-warning-text: #242424;
    --color-sf-warning-bg-color-hover: #de590b;
    --color-sf-warning-border-color-hover: var(--color-sf-warning-bg-color-hover);
    --color-sf-warning-text-hover: var(--color-sf-primary-text-color);
    --color-sf-warning-bg-color-pressed: #4a1e04;
    --color-sf-warning-border-color-pressed: var(--color-sf-warning-bg-color-pressed);
    --color-sf-warning-text-pressed: var(--color-sf-primary-text-color);
    --color-sf-warning-bg-color-focus: var(--color-sf-warning-bg-color);
    --color-sf-warning-border-color-focus: var(--color-sf-warning-bg-color-focus);
    --color-sf-warning-text-focus: var(--color-sf-primary-text-color);
    --color-sf-warning-bg-color-disabled: var(--color-sf-primary-bg-color-disabled);
    --color-sf-warning-border-color-disabled: var(--color-sf-warning-bg-color-disabled);
    --color-sf-warning-text-disabled: var(--color-sf-primary-text-disabled);
    --color-sf-warning-bg-color-selected: #8a3707;
    --color-sf-warning-border-color-selected: var(--color-sf-warning-bg-color-selected);
    --color-sf-warning-text-selected: var(--color-sf-primary-text-color);
    --color-sf-info-bg-color: #18a4c4;
    --color-sf-info-border-color: var(--color-sf-info-bg-color);
    --color-sf-info-text: #242424;
    --color-sf-info-bg-color-hover: var(--color-sf-info-bg-color);
    --color-sf-info-border-color-hover: var(--color-sf-info-bg-color-hover);
    --color-sf-info-text-hover: var(--color-sf-primary-text-color);
    --color-sf-info-bg-color-pressed: #002e38;
    --color-sf-info-border-color-pressed: var(--color-sf-info-bg-color-pressed);
    --color-sf-info-text-pressed: var(--color-sf-primary-text-color);
    --color-sf-info-bg-color-focus: var(--color-sf-info-bg-color);
    --color-sf-info-border-color-focus: var(--color-sf-info-bg-color-focus);
    --color-sf-info-text-focus: var(--color-sf-primary-text-color);
    --color-sf-info-bg-color-disabled: var(--color-sf-primary-bg-color-disabled);
    --color-sf-info-border-color-disabled: var(--color-sf-info-bg-color-disabled);
    --color-sf-info-text-disabled: var(--color-sf-primary-text-disabled);
    --color-sf-info-bg-color-selected: #005669;
    --color-sf-info-border-color-selected: var(--color-sf-info-bg-color-selected);
    --color-sf-info-text-selected: var(--color-sf-primary-text-color);
    --color-sf-danger-bg-color: #cc2635;
    --color-sf-danger-border-color: var(--color-sf-danger-bg-color);
    --color-sf-danger-text: var(--color-sf-primary-text-color);
    --color-sf-danger-bg-color-hover: var(--color-sf-danger-bg-color);
    --color-sf-danger-border-color-hover: var(--color-sf-danger-bg-color-hover);
    --color-sf-danger-text-hover: var(--color-sf-primary-text-color);
    --color-sf-danger-bg-color-pressed: #3b0509;
    --color-sf-danger-border-color-pressed: var(--color-sf-danger-bg-color-pressed);
    --color-sf-danger-text-pressed: var(--color-sf-primary-text-color);
    --color-sf-danger-bg-color-focus: var(--color-sf-danger-bg-color);
    --color-sf-danger-border-color-focus: var(--color-sf-danger-bg-color-focus);
    --color-sf-danger-text-focus: var(--color-sf-primary-text-color);
    --color-sf-danger-bg-color-disabled: var(--color-sf-primary-bg-color-disabled);
    --color-sf-danger-border-color-disabled: var(--color-sf-danger-bg-color-disabled);
    --color-sf-danger-text-disabled: var(--color-sf-primary-text-disabled);
    --color-sf-danger-bg-color-selected: #6e0811;
    --color-sf-danger-border-color-selected: var(--color-sf-danger-bg-color-selected);
    --color-sf-danger-text-selected: var(--color-sf-primary-text-color);
    --color-sf-primary-outline: var(--color-sf-primary);
    --color-sf-secondary-outline: var(--color-sf-secondary-border-color);
    --color-sf-warning-outline: #f7630c;
    --color-sf-danger-outline: #c50f1f;
    --color-sf-success-outline: #107c10;
    --color-sf-info-outline: #0099bc;
    --color-sf-tooltip-bg-color: var(--color-sf-content-bg-color-alt4);
    --color-sf-tooltip-border: var(--color-sf-tooltip-bg-color);
    --color-sf-tooltip-text-color: var(--color-sf-content-text-color);
    --color-sf-toast-text-color: var(--color-sf-content-text-color);
    --color-sf-toast-alt-text-color: var(--color-sf-content-text-color-alt1);
    --color-sf-appbar-bg-color-alt1: var(--color-sf-content-bg-color-alt2);
    --color-sf-appbar-color-alt1: var(--color-sf-content-text-color);
    --color-sf-appbar-border-color-alt1: var(--color-sf-content-bg-color-alt2);
    --color-sf-appbar-hover-bg-color-alt1: transparent;
    --color-sf-appbar-bg-color-alt2: #f5f5f5;
    --color-sf-appbar-color-alt2: #242424;
    --color-sf-appbar-border-color-alt2: var(--color-sf-appbar-bg-color-alt2);
    --color-sf-appbar-hover-bg-color-alt2: transparent;
    --color-sf-diagram-palette-background: #e0e0e0;
    --color-sf-diagram-palette-hover-background: #bdbdbd;
    --color-sf-diagram-palette-selected-background: var(--color-sf-diagram-palette-hover-background);
    --color-sf-rating-selected-color: #fff;
    --color-sf-rating-unrated-color: var(--color-sf-content-text-color);
    --color-sf-rating-selected-disabled-color: #575757;
    --color-sf-rating-unrated-disabled-color: #4d4d4d;
    --color-sf-rating-selected-hover-color: #2886de;
    --color-sf-rating-unrated-hover-color: #115ea3;
    --color-sf-rating-pressed-color: #479ef5;
    --color-sf-msg-color: var(--color-sf-content-text-color);
    --color-sf-msg-bg-color: var(--color-sf-content-bg-color-alt2);
    --color-sf-msg-border-color: var(--color-sf-border);
    --color-sf-msg-color-alt1: var(--color-sf-content-text-color);
    --color-sf-msg-bg-color-alt1: transparent;
    --color-sf-msg-border-color-alt1: #666666;
    --color-sf-msg-color-alt2: #242424;
    --color-sf-msg-bg-color-alt2: #c2c2c2;
    --color-sf-msg-border-color-alt2: var(--color-sf-msg-bg-color-alt2);
    --color-sf-msg-icon-color: var(--color-sf-msg-bg-color-alt2);
    --color-sf-msg-icon-color-alt1: var(--color-sf-msg-icon-color);
    --color-sf-msg-icon-color-alt2: #fff;
    --color-sf-msg-close-icon-color: var(--color-sf-content-text-color-alt1);
    --color-sf-msg-close-icon-color-alt1: var(--color-sf-msg-close-icon-color);
    --color-sf-msg-close-icon-color-alt2: #fff;
    --color-sf-msg-success-color: var(--color-sf-content-text-color);
    --color-sf-msg-success-bg-color: #052505;
    --color-sf-msg-success-border-color: #107c10;
    --color-sf-msg-success-color-alt1: #54b054;
    --color-sf-msg-success-bg-color-alt1: transparent;
    --color-sf-msg-success-border-color-alt1: #218c21;
    --color-sf-msg-success-color-alt2: #fff;
    --color-sf-msg-success-bg-color-alt2: #107c10;
    --color-sf-msg-success-border-color-alt2: var(--color-sf-msg-success-bg-color-alt2);
    --color-sf-msg-success-icon-color: #54b054;
    --color-sf-msg-success-icon-color-alt1: var(--color-sf-msg-success-icon-color);
    --color-sf-msg-success-icon-color-alt2: #fff;
    --color-sf-msg-success-close-icon-color: var(--color-sf-content-text-color-alt1);
    --color-sf-msg-success-close-icon-color-alt1: var(--color-sf-msg-success-close-icon-color-alt1);
    --color-sf-msg-success-close-icon-color-alt2: #fff;
    --color-sf-msg-danger-color: var(--color-sf-content-text-color);
    --color-sf-msg-danger-bg-color: #3b0509;
    --color-sf-msg-danger-border-color: #c50f1f;
    --color-sf-msg-danger-color-alt1: #dc626d;
    --color-sf-msg-danger-bg-color-alt1: transparent;
    --color-sf-msg-danger-border-color-alt1: #d33f4c;
    --color-sf-msg-danger-color-alt2: #fff;
    --color-sf-msg-danger-bg-color-alt2: #c50f1f;
    --color-sf-msg-danger-border-color-alt2: var(--color-sf-msg-danger-bg-color-alt2);
    --color-sf-msg-danger-icon-color: #dc626d;
    --color-sf-msg-danger-icon-color-alt1: var(--color-sf-msg-danger-icon-color);
    --color-sf-msg-danger-icon-color-alt2: #fff;
    --color-sf-msg-danger-close-icon-color: var(--color-sf-content-text-color-alt1);
    --color-sf-msg-danger-close-icon-color-alt1: var(--color-sf-msg-danger-close-icon-color-alt1);
    --color-sf-msg-danger-close-icon-color-alt2: #fff;
    --color-sf-msg-info-color: var(--color-sf-content-text-color);
    --color-sf-msg-info-bg-color: #002e38;
    --color-sf-msg-info-border-color: #0099bc;
    --color-sf-msg-info-color-alt1: #56bfd7;
    --color-sf-msg-info-bg-color-alt1: transparent;
    --color-sf-msg-info-border-color-alt1: #31afdd;
    --color-sf-msg-info-color-alt2: #fff;
    --color-sf-msg-info-bg-color-alt2: #0099bc;
    --color-sf-msg-info-border-color-alt2: var(--color-sf-msg-info-bg-color-alt2);
    --color-sf-msg-info-icon-color: #56bfd7;
    --color-sf-msg-info-icon-color-alt1: var(--color-sf-msg-info-icon-color);
    --color-sf-msg-info-icon-color-alt2: #fff;
    --color-sf-msg-info-close-icon-color: var(--color-sf-content-text-color-alt1);
    --color-sf-msg-info-close-icon-color-alt1: var(--color-sf-msg-info-close-icon-color-alt1);
    --color-sf-msg-info-close-icon-color-alt2: #fff;
    --color-sf-msg-warning-color: var(--color-sf-content-text-color);
    --color-sf-msg-warning-bg-color: #411200;
    --color-sf-msg-warning-border-color: #da3b01;
    --color-sf-msg-warning-color-alt1: #e9835e;
    --color-sf-msg-warning-bg-color-alt1: transparent;
    --color-sf-msg-warning-border-color-alt1: #e36537;
    --color-sf-msg-warning-color-alt2: #fff;
    --color-sf-msg-warning-bg-color-alt2: #da3b01;
    --color-sf-msg-warning-border-color-alt2: var(--color-sf-msg-warning-bg-color-alt2);
    --color-sf-msg-warning-icon-color: #e9835e;
    --color-sf-msg-warning-icon-color-alt1: var(--color-sf-msg-warning-icon-color);
    --color-sf-msg-warning-icon-color-alt2: #fff;
    --color-sf-msg-warning-close-icon-color: var(--color-sf-content-text-color-alt1);
    --color-sf-msg-warning-close-icon-color-alt1: var(--color-sf-msg-warning-close-icon-color-alt1);
    --color-sf-msg-warning-close-icon-color-alt2: #fff;
    --color-sf-shadow-color: #fff;
    --color-sf-shadow-color1: #000;
    --color-sf-link-button: var(--color-sf-primary);
    --color-sf-link-button-hover: #0f6cbd;
    --color-sf-link-button-pressed: #115ea3;
    --color-sf-link-button-focus: #115ea3;
    --color-sf-link-button-border-focus: #fff;
    --color-sf-chip-bg-color: #141414;
    --color-sf-chip-border-color: #141414;
    --color-sf-chip-bg-disabled: #141414;
    --color-sf-chip-border-disabled: rgba(0, 0, 0, 0);
    --color-sf-secondary-outline-button-text-color-hover: #fff;
    --color-sf-secondary-outline-button-text-color-pressed: #fff;
    --color-sf-secondary-outline-button-text-color-selected: #fff;
    --color-sf-secondary-outline-button-text-color-focus: #fff;
    --color-sf-slider-shadow1: #000;
    --color-sf-slider-shadow2: #666666;
    --color-sf-slider-disabled: #666666;
    --color-sf-slider-border-color: rgba(255, 255, 255, 0);
    --color-sf-slider-bg-disabled: #525252;
    --color-sf-toggle-switch-border-disabled: #141414;
    --color-sf-calendar-icon-color: #1153a3;
    --color-sf-calendar-header-text-color-pressed: #62abf5;
    --color-sf-calendar-today-bg-color: #1153a3;
    --color-sf-dialog-border: #1f1f1f;
    --color-sf-badge-light-bg-color: #333333;
    --color-sf-secondary-badge-border-color: #292929;
    --color-sf-secondary-badge-bg-color: #292929;
    --color-sf-secondary-badge-bg-color-focus: #292929;
    --color-sf-skeleton-bg-color: #575757;
    --color-sf-tab-selected-bg-color-disabled: #115ea3;
    --color-sf-tab-selected-text-color-disabled: #a3a3a3;
    --color-sf-ribbon-border-color: #00000000;
    --color-sf-toolbar-icon-color: #479ef5;
    --color-sf-contextual-text: #fff;
    --color-sf-cell-selection-bg-color: #08233b;
    --color-sf-document-editor-border-color: #00000000;
    --color-sf-holiday-bg-color: #141414;
    --color-sf-taskbar-bg: #b4d6fa;
    --color-sf-taskbar-border: #0f6cbd;
    --color-sf-taskbar-progress: #0f6cbd;
    --color-sf-sort-bg-color: #333333;
}
