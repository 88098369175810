/* You can add global styles to this file, and also import other style files */
@import '~@csstools/normalize.css';
@import './syncfusion.scss';
@import './theme-base.scss';
@import './theme-dark.scss';
@import './rag-palette.scss';
@import './material.scss';
@import './graphing.scss';
@import './third-party-style-overwrites.scss';
@import './tower.scss';
@import './tower-3d.scss';
@import './optimization.scss';
@import './ngx-swiper-wrapper.scss';

html {
  position: fixed;
}

html,
body {
  font-size: var(--font-size);
  overflow: hidden;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-tower-y-axis line,
.app-credit-tower-y-axis line {
  stroke: #ffffff;
}

.app-tower-y-axis path,
.app-credit-tower-y-axis path {
  stroke: #ffffff;
}

.app-tower-y-axis text {
  stroke: #ffffff;
}

.app-credit-tower-y-axis text {
  font-size: var(--font-size-smaller);
  font-family: var(--font-family);
}

.app-tower-x-axis line,
.app-credit-tower-x-axis line {
  stroke: #ffffff;
}

.app-tower-x-axis path,
.app-credit-tower-x-axis path {
  stroke: #ffffff;
}

.app-tower-x-axis text {
  stroke: #ffffff;
}

.app-credit-tower-x-axis text {
  font-size: var(--font-size-smaller);
  font-family: var(--font-family);
}

.app-credit-tower-x-hover-line {
  stroke: #ffffff;
  stroke-width: 2px;
  stroke-dasharray: 5, 5;
}

.app-credit-tower-x-date-line {
  stroke: var(--primary);
  stroke-width: 3px;
  stroke-dasharray: 5, 5;
}

/* Scrollbars */

::-webkit-scrollbar {
  width: 14px;
  height: 12px;
  border-radius: 12px;
  background-color: transparent;
}

/* Prevent white scrollbar when dialog open */
body::-webkit-scrollbar {
  background-color: var(--bg);
  border-radius: 0;
}

::-webkit-scrollbar:hover {
  border: 1px solid var(--border);
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: var(--bg-3-lit);
}

::-webkit-scrollbar-thumb:active {
  border-radius: 12px;
  background-color: var(--border-2);
}

::-webkit-scrollbar-thumb:vertical {
  background-clip: padding-box;
  border: 2px solid transparent;
  min-height: 50px;
}

::-webkit-scrollbar-thumb:horizontal {
  background-clip: padding-box;
  border: 2px solid transparent;
  min-width: 50px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
  color: transparent;
}

.app-elevation-3::-webkit-scrollbar:hover {
  border: 1px solid var(--border-1);
}

.app-elevation-3::-webkit-scrollbar-thumb {
  background-color: var(--border-1);
}

.app-elevation-3::-webkit-scrollbar-thumb:active {
  background-color: var(--bg);
}

/* Headers */

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-header-family);
  font-weight: var(--font-header-weight);
  line-height: calc(1rem * (18 / 14));
  margin: calc(2rem - 0.14285714em) 0 1rem;
  padding: 0;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: var(--font-size-gigantic);
  min-height: 1rem;
  line-height: 2.5rem;
}

h2 {
  font-size: var(--font-size-huge);
}

.app-markdown {
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  a {
    color: var(--accent);
  }
}

.axisGraph {
  font: 12px sans-serif;
}

.tooltip {
  fill: white;
  stroke: #000;
  opacity: 0;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  z-index: 100000;
  position: absolute;
  text-align: center;
  display: inline-block;
  width: auto;
  height: auto;
  padding: 2px;
  font: 19px sans-serif;
  left: 723px;
  top: 522px;
}

.custom-tooltip {
  fill: white;
  stroke: #000;
  opacity: 0;
  background: white;
  color: black;
  border: solid;
  border-width: 2px;
  border-radius: 8px;
  pointer-events: none;
  z-index: 100000;
  position: absolute;
  text-align: center;
  display: inline-block;
  width: auto;
  min-width: 50px;
  height: auto;
  padding: 5px;
  font: 14px var(--font-family);
}

.tooltip-series,
.tooltip-point,
.tooltip-value {
  font-weight: bold;
}

.focus circle {
  fill: steelblue;
}

.focus text {
  font-size: 14px;
}

.x-hover-line {
  stroke: steelblue;
  stroke-width: 2px;
  stroke-dasharray: 2, 4;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* Hidden */
.hidden {
  display: none !important;
}

#loader {
  color: #008cff;
  font-family: 'Helvetica Neue', 'calibiri';
  font-size: 16px;
  height: 40px;
  left: 45%;
  position: absolute;
  top: 45%;
  width: 30%;
}
.e-code-mirror::before {
  content: '\e345';
}
.CodeMirror-linenumber,
.CodeMirror-gutters {
  display: none;
}

.char_block.e-active {
  /* box-shadow: inset 3px 3px 7px 0px; */
  outline: 1.5px solid;
}

.char_block {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 5px 5px 0;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #dddddd;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-roatate-left::before {
  content: '\e76e';
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-roatate-right::before {
  content: '\e726';
}

.e-richtexteditor textarea.e-content {
  float: left;
}
.e-richtexteditor .e-rte-content {
  overflow: hidden;
}
.e-rte-content .e-content.e-pre-source {
  width: 100%;
}

.e-icon-btn.e-active .e-md-preview::before {
  content: '\e350';
}

.e-icon-btn .e-md-preview.e-icons::before {
  content: '\e345';
}
