// @import '~swiper/dist/css/swiper.min.css';

// Use accent color: #00B3AA
.swiper-button-next {
  top: 99%;
  right: 47%;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%2300B3AA'%2F%3E%3C%2Fsvg%3E");
  outline: none;
}

.swiper-button-prev {
  top: 99%;
  left: 47%;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%2300B3AA'%2F%3E%3C%2Fsvg%3E");
  outline: none;
}

@media (max-width: 1400px) {
  .swiper-button-next {
    right: 44.5%;
  }
  .swiper-button-prev {
    left: 44.5%;
  }
}

@media (max-width: 900px) {
  .swiper-button-next {
    right: 42%;
  }
  .swiper-button-prev {
    left: 42%;
  }
}

@media (max-width: 650px) {
  .swiper-button-next {
    right: 38%;
  }
  .swiper-button-prev {
    left: 38%;
  }
}

.swiper-button-disabled {
  display: none;
}
