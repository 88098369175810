:root,
html {
  .color-picker {
    border: 2px solid var(--border-1);
    border-radius: var(--border-radius);
    background-color: var(--bg-1);
    padding: var(10px);

    .arrow.arrow-left {
      border-color: transparent transparent transparent var(--border-1);
    }
    .arrow.arrow-right {
      border-color: transparent var(--border-1) transparent transparent;
    }
    .arrow.arrow-bottom {
      border-color: transparent transparent var(--border-1) transparent;
    }
    .arrow.arrow-top {
      border-color: var(--border-1) transparent transparent;
    }

    .saturation-lightness {
      border-radius: var(--border-radius);
      .cursor {
        border: 2px solid var(--border-1-lit);
      }
    }

    .hue-alpha {
      .left {
        .selected-color-background {
          visibility: hidden;
        }
        .selected-color {
          border: 2px solid var(--border-1);
        }
      }
      .right {
        .hue {
          .cursor {
            border: 2px solid var(--border-1-lit);
          }
        }
      }
    }

    .preset-area {
      hr {
        display: none;
      }
      .preset-label {
        color: var(--subtle);
        font-family: var(--font-header-family);
        font-size: var(--font-size-tiny);
        font-weight: var(--font-header-weight);
        letter-spacing: 0.3px;
      }
      .cp-preset-colors-class {
        .preset-color {
          border: 2px solid var(--border-1);

          &:hover {
            border: 2px solid var(--border-1-lit);
          }
        }
      }
    }

    .button-area {
      button {
        border-radius: var(--border-radius);
        border: 1px var(--border-2);
        font-family: var(--font-family);
        font-weight: var(--font-header-weight);
        font-size: var(--font-size-smaller);
        cursor: pointer;
      }

      .cp-cancel-button-class {
        background-color: var(--bg-2-lit);
        color: var(--accent);
      }
      .cp-ok-button-class {
        background-color: var(--accent);
        color: var(--bg-1);
      }
    }
  }
}
