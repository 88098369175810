.app-theme-dark .optimization {
  .mat-step-header .mat-step-icon {
      background-color: rgba(255, 255, 255, 0.7);
      color: var(--body-inverse);
  }

  .mat-step-header .mat-step-icon-selected {
    background-color: var(--accent);
    color: var(--body-inverse);
  }

  .mat-step-header {
    font-size: var(--font-size);
  }

  .mat-step-header .mat-step-label {
    font-family: var(--font-header-family);
  }

  .mat-step-header .mat-step-label.mat-step-label-selected {
    font-weight: var(--font-header-weight);
  }

  .mat-horizontal-stepper-header-container {
    height: var(--header-height-small);
  }

  .mat-horizontal-content-container {
    height: calc(100% - var(--header-height-small) - var(--footer-height));
    box-sizing: border-box;
    padding: 0 var(--inset-big) var(--inset-big) var(--inset-big);
  }
}

[mat-sort-header].cdk-program-focused .mat-sort-header-container {
  border-bottom: 0 !important;
}

.optimization-dialog mat-dialog-container {
  padding: var(--inset);
  box-sizing: border-box !important;
}

.optimization-dialog .mat-mdc-dialog-container {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      display: flex;
    }
  }
}

.optimization-tail-metrics {
  white-space: break-spaces;
  font-size: 0.8rem;
}

.optimization-tail-metrics-menu.mat-mdc-menu-panel {
  max-width: 30rem !important;
  opacity: 0.9;

  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    padding: 13px;
  }
}
