@use '@angular/material' as mat;

@include mat.core();

$app-font-size: 16px;

/* Override material's default typography w/ our app's */
//$app-typography: mat-typography-config(
//  $font-family: var(--font-family),
//  $body-1: mat-typography-level($app-font-size, 20px),
//);

//@include mat-core($app-typography);

/* Color palette */

$app-white: rgb(255, 255, 255);
$app-black: rgb(0, 0, 0);

$app-grey: (
  200: hsl(0, 0%, 93%),
  300: hsl(0, 0%, 88%),
  400: hsl(0, 0%, 76%),
  500: hsl(0, 0%, 66%),
  700: hsl(0, 0%, 39%),
  800: hsl(0, 0%, 26%),
  900: hsl(0, 0%, 13%),
  contrast: (
    200: $app-black,
    300: $app-black,
    400: $app-black,
    500: $app-black,
    700: $app-white,
    800: $app-white,
    900: $app-white,
  ),
);

$app-blue: (
  100: hsl(204, 53%, 72%),
  200: hsl(205, 78%, 70%),
  400: hsl(206, 73%, 53%),
  500: hsl(206, 73%, 47%),
  700: hsl(206, 73%, 36%),
  contrast: (
    100: $app-black,
    200: $app-black,
    400: $app-black,
    500: $app-white,
    700: $app-white,
  ),
);

$app-teal: (
  100: rgb(160, 221, 218),
  200: rgb(113, 223, 217),
  400: rgb(0, 179, 170),
  500: rgb(0, 148, 141),
  700: rgb(0, 97, 92),
  contrast: (
    100: $app-black,
    200: $app-black,
    400: $app-black,
    500: $app-white,
    700: $app-white,
  ),
);

$app-red: (
  100: hsl(7.1, 63%, 82%),
  300: hsl(7.1, 74%, 70%),
  500: hsl(7.1, 87.4%, 56.5%),
  700: hsl(7.1, 87.4%, 45.5%),
  contrast: (
    100: $app-black,
    300: $app-black,
    500: $app-white,
    700: $app-white,
  ),
);

/* Set the material theme's colors */

$app-primary: mat.define-palette($app-grey, 700, 500, 900);
$app-accent: mat.define-palette($app-teal, 500, 100, 700);
$app-warn: mat.define-palette($app-red, 500, 100, 700);
$app-theme: mat.define-light-theme($app-primary, $app-accent);

@include mat.core-theme($app-theme);

$app-primary-dark: mat.define-palette($app-grey, 400, 800, 200);
$app-accent-dark: mat.define-palette($app-teal, 400, 700, 200);
$app-warn-dark: mat.define-palette($app-red, 500, 700, 300);
$app-dark-theme: mat.define-dark-theme($app-primary-dark, $app-accent-dark);

.app-theme-dark {
  /* Set the material theme's colors */
  @include mat.core-theme($app-dark-theme);
}

/* Tooltip */

.app-tooltip-small {
  font-size: var(--font-size-small);
  font-family: var(--font-header-family);
}

/* Menu */

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}
.mat-mdc-menu-panel {
  background: transparent;
}
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.mat-autocomplete-panel mat-option {
  line-height: 20px;
}
body .mat-mdc-menu-item,
.app-theme-light .mat-menu-item,
.app-theme-dark .mat-menu-item {
  color: var(--body);
}

.app-theme-dark .mat-mdc-tab-header-pagination-chevron {
  border-color: white;
}

/* Table */

body,
.app-theme-dark {
  .mat-mdc-table.app-table {
    background: transparent;

    tr.mat-mdc-header-row,
    tr.mat-mdc-footer-row,
    tr.mat-mdc-row {
      height: var(--table-row-height) !important;
    }

    mat-header-row,
    th.mat-mdc-header-cell {
      vertical-align: bottom;
      padding-bottom: 0.4rem;
      border-bottom-color: var(--border-1-lit);
      font-family: var(--font-header-family);
      font-weight: var(--font-header-weight);
      font-size: var(--font-size);
      color: var(--subtle);

      &.mat-mdc-table-sticky {
        background: var(--bg);
      }
    }

    tr.mat-mdc-footer-row {
      height: 45px !important;
    }

    td.mat-mdc-footer-cell {
      vertical-align: bottom;
      padding-top: 0.6rem;
      padding-bottom: 0.7rem;
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: var(--border-1);
      border-bottom-width: 0;
      font-family: var(--font-header-family);
      font-weight: var(--font-header-weight);
      font-size: var(--font-size);
      color: var(--body);

      &.mat-mdc-table-sticky {
        background: var(--bg);
      }
    }

    th.mat-mdc-header-cell.select-all {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    td.mat-mdc-cell.select-all {
      padding-right: var(--inset-tiny);
    }

    mat-row,
    mat-footer-row,
    td.mat-mdc-cell,
    td.mat-mdc-footer-cell {
      border-bottom-color: var(--border);
    }

    .mdc-data-table__row:last-child .mdc-data-table__cell {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    &.app-table-small {
      mat-header-row,
      th.mat-mdc-header-cell,
      mat-row,
      mat-footer-row,
      td.mat-mdc-cell,
      td.mat-mdc-footer-cell {
        font-size: var(--font-size-small);
      }
    }

    &.app-table-tiny {
      mat-header-row,
      th.mat-mdc-header-cell,
      mat-row,
      mat-footer-row,
      td.mat-mdc-cell,
      td.mat-mdc-footer-cell {
        font-size: var(--font-size-tiny);
      }

      tr.mat-mdc-header-row,
      tr.mat-mdc-footer-row,
      tr.mat-mdc-row {
        height: 22px !important;
      }
    }

    .mat-mdc-cell {
      color: var(--body);
    }
  }
}

@media screen and (max-width: 1240px) {
  .mat-mdc-table.app-table {
    tr.mat-mdc-header-row,
    tr.mat-mdc-footer-row,
    tr.mat-mdc-row {
      height: 25px !important;
    }
  }
}

/* Paginator */

.mat-mdc-paginator,
.app-theme-dark .mat-paginator {
  background: var(--bg);
  border-top: 1px solid var(--border);
  &.light-mode {
    color: black !important;
  }
}

/* Button Toggles */

.mdc-checkbox__background,
.mdc-checkbox__ripple {
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease;
}

// Add class .mat-button-default to the default button in a toggle group for highlighting
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-group.app-mat-theme {
  border: solid 2px var(--border-1);
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle {
    background: transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle.mat-button-default:not(.mat-button-toggle-checked) {
    button {
      span {
        color: var(--accent);
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle + .mat-button-toggle {
    border-left: solid 1px var(--border-1);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-label-content {
    line-height: 24px;
    font-size: var(--font-size-small);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-button {
    color: var(--primary);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-checked.mat-button-default {
    background-color: var(--accent);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-checked {
    background-color: var(--primary);
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle-label-content {
      color: var(--body-inverse);
      font-weight: var(--font-link-weight);
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-group.app-mat-theme.app-button-toggle-group-small {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-label-content {
    line-height: 20px;
    font-size: var(--font-size-tiny);
  }
}

@media screen and (max-width: 1240px) {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-group.app-mat-theme {
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle-label-content {
      line-height: 20px;
      font-size: var(--font-size-tiny);
    }
  }
}

/* Dialogs / Panels / Snack Bars */

.mat-mdc-dialog-title {
  font-family: var(--font-header-family);
  font-weight: var(--font-header-weight);
}

.mat-mdc-menu-panel {
  &.app-menu-no-max-width {
    max-width: 100vw !important;
  }
}

.mat-mdc-snack-bar-container {
  max-width: 50vw !important;
}

.mat-mdc-dialog-container {
  --mat-dialog-transition-duration: 0ms !important;
}

.mdc-dialog__surface {
  background-color: transparent !important;
}

/* Override the default dialog, making it transparent */
@mixin mat-dialog-reset() {
  .mat-mdc-dialog-container {
    background: transparent;
    border: 0 solid transparent;
    box-shadow: 0 0 transparent;
    padding: 32px;
    color: var(--body);
  }
}

@mixin mat-dialog-reset-no-padding() {
  .mat-mdc-dialog-container {
    background: transparent;
    padding: 0;
    border: 1px solid var(--border);
    box-shadow: var(--shadow-big);
  }
}

.app-theme-dark {
  .mat-mdc-snack-bar-container.app-error {
    color: var(--body);
    background: var(--bg-3);
    button {
      color: var(--warn);
    }
  }
  .mat-mdc-snack-bar-container.app-success {
    color: var(--body);
    background: var(--bg-3);
    button {
      color: var(--accent);
    }
  }

  .mat-mdc-snack-bar-container {
    color: white;
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      --mat-mdc-snack-bar-button-color: white;
    }
  }

  .snackbar-white-bg {
    color: black;
    --mdc-snackbar-container-color: white;
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
      --mat-mdc-snack-bar-button-color: black;
    }
  }
}

.app-theme-dark {
  .mat-mdc-menu-panel,
  .mat-mdc-dialog-container {
    background: var(--bg);
    border: 5px solid var(--border-3);
    border-radius: var(--border-radius-big);
    box-sizing: content-box;
    color: var(--body);
    padding: 1rem;
  }
}

.app-dialog-invisible,
.app-dialog-reset,
.app-theme-dark .app-dialog-invisible,
.app-theme-dark .app-dialog-reset {
  @include mat-dialog-reset();
}

.app-dialog-reset-no-padding,
.app-theme-dark .app-dialog-reset-no-padding {
  @include mat-dialog-reset-no-padding();
}

.app-dialog-translucent,
.app-theme-dark .app-dialog-translucent {
  @include mat-dialog-reset();

  .mat-mdc-dialog-container {
    background: var(--bg-2-translucent) !important;
    border: 1px solid var(--border-2);
    backdrop-filter: blur(25px);
    padding: 24px;
    box-shadow: var(--shadow-big);
    overflow-x: hidden;
    padding: 0 !important;
    .mdc-dialog__container {
      background: transparent;
      padding: 0 !important;
      .mat-mdc-dialog-surface .mdc-dialog__surface {
        --mdc-dialog-container-color: transparent;
        background: transparent;
        padding: 0 !important;
      }
    }
  }
}

.app-theme-dark .app-prove-backdrop {
  backdrop-filter: blur(8px);
}

.app-prove-backdrop.app-prove-no-scroll .mat-mdc-dialog-container {
  overflow-y: hidden;
}

.app-dialog-with-sidebar,
.app-theme-dark .app-dialog-with-sidebar {
  @include mat-dialog-reset();

  --mdc-dialog-container-color: transparent;

  .mat-mdc-dialog-container {
    padding: 20px;
  }

  .app-dialog-body {
    border: 1px solid var(--border);
    border-top-right-radius: var(--border-radius-huge);
    border-bottom-right-radius: var(--border-radius-huge);
    backdrop-filter: blur(25px);
  }

  .app-dialog-sidebar {
    background: var(--bg-2-translucent);
    border: 1px solid var(--border-2);
    border-right-width: 0;
    border-top-left-radius: var(--border-radius-huge);
    border-bottom-left-radius: var(--border-radius-huge);
    backdrop-filter: blur(25px);
  }
}

.mat-mdc-menu-panel {
  background: transparent;
  border-color: rgba(180, 180, 180, 0.5);

  .mat-mdc-menu-content {
    background: rgba(255, 255, 255, 0.9);
  }
}

.app-theme-dark {
  .mat-mdc-menu-panel {
    background: var(--bg);
    border: 5px solid var(--border-3);
    border-radius: var(--border-radius-big);
    min-width: unset !important;
    max-width: unset;

    .mat-mdc-menu-content {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .mat-mdc-menu-item:hover:not([disabled]) {
    background: var(--accent);
  }

  .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    background: rgba(74, 74, 74, 0.75);
  }
}

.app-theme-light.mat-mdc-menu-panel .mat-mdc-menu-content,
.app-theme-light .mat-mdc-menu-panel .mat-menu-content {
  background: rgb(255, 255, 255, 0.9);
}

.mat-mdc-menu-panel {
  &.app-menu-borderless {
    border-width: 0;

    .mat-mdc-menu-content {
      padding: 0;
    }
  }
}

.app-popover-items-compact.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      height: 38px;
      line-height: 38px;
      font-family: var(--font-header-family);
    }
  }
}

.app-menu-panel-no-min-height.mat-mdc-menu-panel {
  min-height: auto;
}

.app-menu-panel-no-x-scroll.mat-mdc-menu-panel {
  overflow-x: hidden;
}

.app-menu-panel-small.mat-mdc-menu-panel {
  opacity: 0.95;
  background: var(--bg-1);
  border-width: 1px;
  border-color: var(--border-translucent);
  border-radius: var(--border-radius);
  margin-top: var(--stack-tiny);
  min-height: 40px;
  box-shadow: var(--shadow-big);

  .mat-mdc-menu-content {
    background: transparent;
    padding: var(--stack-tiny) 0px;

    .mat-mdc-menu-item {
      height: 32px;
      line-height: 32px;
      font-family: var(--font-header-family);
      font-size: var(--font-size-small);
      font-weight: var(--font-link-weight);
      padding: 0 var(--inset-small);
    }
  }
}

.mat-mdc-form-field-error {
  color: #f44336;
}

.mat-mdc-form-field-subscript-wrapper {
  font-size: 75%;
}

.mdc-text-field {
  display: flex !important;
  flex-direction: row;
  align-items: flex-end !important;
  justify-content: flex-end;
  padding: 0 0 0 8px !important;
}

.mat-mdc-select-trigger {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 4px 0;
}

.mdc-menu-surface {
  background-color: var(--bg-1-lit);
  height: fit-content;
}

.mat-mdc-dialog-surface {
  background-color: black;
}

.mdc-text-field .mdc-floating-label {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  position: relative;
}

.mat-mdc-select-placeholder {
  display: none !important;
}

// ng15 checkboxes not updating styling correctly, manually define checkbox states
.mat-mdc-checkbox:not(.mat-mdc-checkbox-checked, [ng-reflect-indeterminate]) {
  &:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background {
    border-color: var(
      --mdc-checkbox-unselected-icon-color,
      rgba(0, 0, 0, 0.54)
    );
    background-color: transparent !important;
  }

  &.mat-mdc-checkbox-disabled .mdc-checkbox__background {
    background-color: #686868;
  }

  svg {
    color: var(--mdc-checkbox-selected-checkmark-color, #fff) !important;
    opacity: 0 !important;
  }
}

.mat-mdc-checkbox-checked {
  &:not(.mat-mdc-checkbox-disabled) .mdc-checkbox__background {
    border-color: var(
      --mdc-checkbox-selected-icon-color,
      var(--mdc-theme-secondary, #018786)
    ) !important;
    background-color: var(
      --mdc-checkbox-selected-icon-color,
      var(--mdc-theme-secondary, #018786)
    ) !important;
  }
  &.mat-mdc-checkbox-disabled .mdc-checkbox__background {
    background-color: #686868;
  }
  svg {
    color: var(--mdc-checkbox-selected-checkmark-color, #fff) !important;
    opacity: 1 !important;

    path {
      stroke-dashoffset: 0;
    }
  }
}

mat-option {
  width: calc(100% - 32px) !important;
}

mat-option > span {
  width: 100% !important;
  display: flex !important;
  flex-direction: row;
  align-items: center !important;
}

mat-option > span > div.item {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  span {
    width: fit-content !important;
  }
  .mat-icon {
    width: 24px !important;
    margin-left: 24px !important;
  }
}

.mat-mdc-select-panel {
  width: fit-content;
  overflow-x: hidden !important;
  min-width: calc(100% + 32px);
  border-radius: var(--border-radius-big);
}

.reinsurer-panel {
  width: 288px !important;
}

.mat-mdc-select-arrow {
  width: 10px;
  height: 5px;
  position: relative;
}

.admin-dialog-box {
  max-width: 100vw;
  .mat-mdc-dialog-container {
    width: 800px !important;
  }
}

.reinsurerDetail-dialog-box {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      overflow-x: hidden !important;
    }
  }
}

.custom-dialog-box {
  .mat-mdc-dialog-container {
    overflow: auto;
  }
}

.app-tier-structures-dialog,
.app-credit-tier-structures-dialog {
  max-width: 100vw;
  .mat-mdc-dialog-container {
    padding: var(--stack) 0 0 var(--inset);
    border-width: 0;
    background: var(--bg-1);
  }
}

.app-tier-structures-select-dialog {
  max-width: 100vw;
  .mat-mdc-dialog-container {
    padding: var(--stack) 0 0 var(--inset);
    border-width: 0;
    background: var(--bg-1);
  }
}

/* Mat Select */
.app-theme-dark .mat-mdc-form-field-type-mat-select.mat-form-field-disabled {
  .mdc-line-ripple::before {
    border-bottom-style: dashed;
  }
  .mat-mdc-select-disabled .mat-mdc-select-value {
    color: rgba(255, 255, 255, 0.5);
  }
}

.cdk-overlay-pane {
  min-width: fit-content;
}

.app-bar-panel {
  min-width: fit-content;
}

/* Nav List */
.app-theme-dark .mat-mdc-nav-list {
  .mat-mdc-list-item {
    padding: 0;

    &:hover {
      background: var(--bg-3);
    }

    &.active {
      background: var(--accent);
    }
    .mdc-list-item__content {
      white-space: nowrap;

      .mdc-list-item__primary-text {
        display: flex !important;
        flex-direction: row;
        color: #fff !important;
        padding: 8px 0 8px 16px;
        align-items: center;
      }
    }
  }
}

.app-theme-dark .mat-drawer {
  background-color: var(--bg-1);
  border-right: 1px solid var(--border-1);
}

.app-theme-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.5);
}

.app-theme-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(255, 255, 255, 0.5);
}

.mat-drawer-inner-container {
  &::-webkit-scrollbar-thumb {
    background-color: var(--subtle);

    &:active {
      background-color: var(--primary);
    }
  }
}

/* Tabs */

.mat-tab-label,
.mat-mdc-tab-link,
.mat-mdc-tab-link,
.mdc-tab__text-label,
.app-theme-dark .mat-tab-label,
.app-theme-dark .mdc-tab__text-label,
.app-theme-dark .mat-mdc-tab-link,
.app-theme-dark .mat-tab-link {
  color: var(--primary);
  opacity: 1;
}

.mat-tab-label .mat-tab-label-active,
.mdc-tab--active .mdc-tab__text-label,
.app-theme-dark .mat-tab-label.mat-tab-label-active,
.mat-mdc-tab-link .mat-tab-label-active,
.app-theme-dark .mat-tab-link.mat-tab-label-active {
  color: var(--body);
}

.mat-mdc-tab-link.mdc-tab--active {
  color: var(--accent);
}

.mat-mdc-tab-group {
  &.app-metrics-tabs {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label {
      height: 81px;
    }
  }
}

.mat-mdc-form-field.app-bar-field .mdc-line-ripple:before {
  display: none;
}

/* Fields */

/* mat-form-field */

/* Override the default bottom padding of mat-form-field */
@mixin mat-form-field-bottom-padding($padding-bottom: 0) {
  .mat-mdc-form-field-wrapper {
    padding-bottom: $padding-bottom;
  }
  /* Adjust the underline's absolute positioning to match the padding */
  .mdc-line-ripple::before {
    bottom: $padding-bottom;
  }
}

/* Adds left and right padding to the mat-mdc-form-field */
@mixin mat-form-field-sides-padding($padding-sides: 0) {
  .mat-mdc-form-field-flex {
    padding-left: $padding-sides;
    padding-right: $padding-sides;
  }
}

/* Change a form field's placeholder color */
@mixin mat-form-field-placeholder-color($color: var(--color-subtle)) {
  .mat-mdc-form-field-placeholder {
    color: $color;
  }
}

.app-mat-form-field-no-padding {
  @include mat-form-field-bottom-padding(0);
}

/* TODO: mdc-line-ripple display none should be inside this class (?) */
/* Style a mat-form-field with no underline */
.app-mat-form-field-no-underline {
}
.mdc-line-ripple::before {
  display: none;
}
.mdc-line-ripple::after {
  display: none;
}

.mat-mdc-input-element,
.app-theme-dark .mat-mdc-input-element {
  caret-color: var(--accent);
  color: var(--body);
}

.mdc-radio__native-control .custom-cursor-on-hover {
  color: white !important;
}

.mdc-text-field {
  border: none !important;
}

.mat-mdc-form-field.app-form-field-no-space {
  @include mat-form-field-bottom-padding(0);
  @extend .app-mat-form-field-no-underline;

  font-family: var(--font-header-family);

  .mat-mdc-form-field-flex {
    padding-top: 0;

    .mat-mdc-form-field-infix {
      border-top-width: 0;
    }
  }

  .mat-mdc-select-value {
    color: var(--accent);
  }
}

.mat-mdc-form-field.app-bar-field {
  @include mat-form-field-bottom-padding(0);
  @extend .app-mat-form-field-no-underline;

  .mat-mdc-select-value {
    color: var(--accent);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.app-theme-dark .mat-mdc-select-panel.app-bar-panel,
.app-theme-dark .mat-mdc-autocomplete-panel,
.app-theme-dark .mat-autocomplete-panel {
  background: var(--bg-1-lit);
  border: 1px solid var(--border-1-lit);
}

.app-theme-dark .mat-mdc-select-panel.app-bar-panel,
.mat-mdc-select-panel.app-bar-panel {
  max-height: 480px;
  &.large {
    min-width: 500px;
  }
  .mat-mdc-option.mat-mdc-selected {
    color: var(--accent);
  }
}

.app-bar-panel.large {
  min-width: 500px;
}

.app-theme-dark .mat-mdc-select-panel.app-select-height-big,
.mat-mdc-select-panel.app-select-height-big {
  max-height: max(256px, 60vh);
}

.app-theme-dark .mat-mdc-select-panel.app-select-height-medium,
.mat-mdc-select-panel.app-select-height-medium {
  max-height: 400px;
}

.app-theme-dark .mat-mdc-select-panel.app-select-width-big,
.mat-mdc-select-panel.app-select-width-big {
  max-width: 400px;
}

.app-theme-dark .mat-mdc-select-panel.fit-width,
.mat-mdc-select-panel.fit-width {
  width: fit-content;
}

.mat-mdc-form-field.app-bar-field .mat-mdc-select-arrow,
.app-theme-dark .mat-mdc-form-field.app-bar-field .mat-mdc-select-arrow,
.mat-mdc-form-field.app-bar-field .mat-mdc-form-field-label,
.app-theme-dark .mat-mdc-form-field.app-bar-field .mat-mdc-form-field-label {
  color: var(--primary);
}

.mat-mdc-form-field.app-bar-field
  .mat-mdc-select-disabled
  .mat-mdc-select-arrow,
.app-theme-dark
  .mat-mdc-form-field.app-bar-field
  .mat-mdc-select-disabled
  .mat-mdc-select-arrow,
.mat-mdc-form-field.app-bar-field
  .mat-mdc-select-disabled
  .mat-mdc-select-value,
.app-theme-dark
  .mat-mdc-form-field.app-bar-field
  .mat-mdc-select-disabled
  .mat-mdc-select-value {
  color: var(--hint);
}

.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float.app-bar-field
  .mat-mdc-form-field-label,
.mat-mdc-form-field-can-float.app-bar-field
  .mat-mdc-input-server:focus
  + .mat-mdc-form-field-label-wrapper
  .mat-mdc-form-field-label {
  color: var(--subtle);
  -webkit-transform: translateY(-1.34375em) scale(0.85);
  transform: translateY(-1.34375em) scale(0.85);
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float.app-bar-field-big
  .mat-mdc-form-field-label,
.mat-mdc-form-field-can-float.app-bar-field-big
  .mat-input-server:focus
  + .mat-mdc-form-field-label-wrapper
  .mat-mdc-form-field-label {
  color: var(--subtle);
  -webkit-transform: translateY(-1.34375em) scale(0.62);
  transform: translateY(-1.34375em) scale(0.62);
}

.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float.app-field-bold-label
  .mat-mdc-form-field-label,
.mat-mdc-form-field-can-float.app-field-bold-label
  .mat-mdc-input-server:focus
  + .mat-mdc-form-field-label-wrapper
  .mat-mdc-form-field-label {
  font-weight: var(--font-header-weight);
}

.mat-mdc-form-field-appearance-standard.mat-mdc-form-field-has-label.app-bar-field
  .mat-mdc-select:not(.mat-mdc-select-empty)
  .mat-mdc-select-arrow-wrapper {
  transform: translateY(-30%);
}

.mat-mdc-form-field {
  &.app-bar-field.app-bar-field-button {
    .mat-mdc-select-value,
    .mat-mdc-select-arrow-wrapper,
    .mat-mdc-select-disabled .mat-mdc-select-trigger,
    .mat-mdc-select-value-text {
      cursor: pointer;
    }
  }
}

.mat-mdc-form-field {
  &.app-bar-field-middle {
    @include mat-form-field-bottom-padding(0.38em);

    .mat-mdc-form-field-flex {
      padding-top: 0.1em;
    }

    .mat-mdc-form-field-prefix {
      top: 7px;
    }

    .mat-mdc-form-field-infix {
      border-top-width: 0;
    }
  }
}

/* Chips */

.app-theme-dark .mat-mdc-chip.app-chip-palette,
.mat-mdc-chip.app-chip-palette {
  color: rgba(var(--rgb), var(--alpha));
  background: rgba(var(--rgb), var(--bg-2-alpha));
  border: 1px solid rgba(var(--rgb), 0.6);
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  .mat-mdc-chip-remove {
    color: rgba(var(--rgb), var(--alpha));
  }
}

.app-theme-dark .mat-expansion-panel {
  background: var(--bg-2);
  border: 1px solid var(--bg-1);
  padding: 1rem;
}

.app-theme-dark .mat-mdc-chip.app-chip-outline,
.mat-mdc-chip.app-chip-outline {
  color: var(--subtle);
  background: transparent;
  border: 1px dashed var(--border-2);
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  .mat-mdc-chip-remove {
    color: var(--primary);
  }
}

.mat-mdc-chip {
  padding: 0 !important;
}

.app-theme-dark .mat-mdc-chip.app-chip-dash,
.mat-mdc-chip.app-chip-dash {
  border: 1px dashed rgba(var(--rgb), 0.6);
}

.mat-mdc-form-field.app-bar-field .mat-datepicker-toggle,
.app-theme-dark .mat-mdc-form-field.app-bar-field .mat-datepicker-toggle {
  color: var(--primary);
  transition: color 250ms;

  &:hover {
    color: var(--primary-lit);
  }

  .mat-mdc-icon-button {
    width: 2em;
    height: 2em;
    line-height: 2em;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-wrapper svg {
      width: 1.25em;
      height: 1.25em;
      line-height: 1.25em;
    }
  }
}

/* Slide Toggle */

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.app-slide-toggle .mat-slide-toggle-content {
  font-family: var(--font-header-family);
  font-weight: var(--font-header-weight);
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after,
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  background: #00b3aa70 !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:disabled
  .mdc-switch__icons,
.mat-mdc-slide-toggle .mdc-switch:disabled .mdc-switch__handle::after {
  opacity: 1 !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: var(--accent) !important;
}
.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--unselected:enabled
  .mdc-switch__handle::after {
  background: var(--subtle) !important;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: #00b3aa !important;
  border-color: #00b3aa !important;
}

.mdc-switch__icon,
.mdc-switch__icon--on {
  display: none;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--unselected:enabled
  .mdc-switch__handle::after {
  background: #bdbdbd !important;
}
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background: var(--subtle) !important;
}

.app-theme-dark .mat-calendar {
  padding: 0 0 24px !important;
  color: #ffffff85;
}

.mat-calendar-arrow {
  filter: invert(1);
  transform: translate(2px, -2px);
}

.mat-calendar-controls {
  color: white;
}

.mat-calendar-controls > button.mdc-icon-button {
  width: 40px !important;
  height: 40px !important;
}

.mat-calendar-table-header {
  font-size: 12px !important;
  border-bottom: 1px solid #ffffff85;
}

.mat-calendar-body-label {
  color: rgb(255, 255, 255, 0.7);
}

.mat-calendar-body-cell-content {
  top: 5%;
  left: 5%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 75% !important;
  height: 75% !important;
  border: none !important;
  color: #ffffff;
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  border: 1px solid white !important;
  border-radius: 999px;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--mdc-radio-unselected-icon-color) !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  line-height: inherit !important;
  font-family: inherit !important;
  text-decoration: inherit !important;
  text-transform: inherit !important;
  margin-right: auto !important;
  max-width: 100% !important;
}

.mat-mdc-option .mdc-list-item__primary-text > span {
  white-space: nowrap !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  line-height: inherit !important;
  font-family: inherit !important;
  text-decoration: inherit !important;
  text-transform: inherit !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  min-width: 0 !important;
}

button {
  font-family: var(--font-family);
}

button.submit {
  background-color: var(--accent) !important;
  color: black !important;
  padding: 8px 16px;
  border-radius: var(--border-radius);
  font-weight: bold;
  font-size: var(--font-size);
}

button.close {
  color: white !important;
  background-color: #424242 !important;
  padding: 8px 16px !important;
  border-radius: var(--border-radius);
  font-weight: bold;
  font-size: var(--font-size);
}

button.closeX {
  display: flex;
  align-items: center;
  color: var(--primary);
  transition: color 250ms;
  background-color: #424242;
  width: 24px;
  height: 24px;
  font-size: 24px !important;
  border-radius: var(--border-radius);
  padding-top: 6px;
  top: 0;
  margin-left: auto;
  &.clear {
    background-color: transparent !important;
    top: 24px;
    right: 24px;
  }
}
button.closeX:not(.disabled):hover,
button.closeX:not(.disabled):focus {
  color: var(--primary-lit);
}

.app-theme-dark
  .mat-mdc-progress-spinner
  .mdc-circular-progress__determinate-circle,
.app-theme-dark
  .mat-mdc-progress-spinner
  .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--accent);
}
