.app-chart {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  vertical-align: middle;

  svg:not(:root) {
    overflow: visible;
  }

  line.gridline-x,
  line.gridline-y {
    stroke: var(--hint);
    opacity: 0.55;
  }

  .annotation-line {
    &.origin line {
      stroke: var(--primary);
    }

    &.transparent-text text {
      fill: transparent;
      stroke: transparent;
    }
  }

  line.median {
    stroke: rgb(var(--rgb), 0.5);
  }

  .median text {
    fill: rgb(var(--rgb));
  }

  text.quadrant {
    fill: var(--body);
    font-size: var(--font-size-huge);
    font-family: var(--font-family);
    font-weight: var(--font-header-weight);
  }

  rect.quadrant {
    fill: rgba(0, 0, 0, 0.5);
  }

  .y-axis,
  .x-axis {
    .tick path,
    path.domain {
      stroke: var(--body);
    }
  }

  .tick text {
    font-size: var(--font-size-smaller);
  }

  g.label {
    circle,
    rect {
      fill: transparent;
    }
    text {
      fill: var(--body);
    }
  }

  d3fc-group.cartesian-chart > .left-label,
  d3fc-group.cartesian-chart > .right-label,
  d3fc-group.cartesian-chart > .bottom-label,
  .tick text {
    fill: var(--body);
    color: var(--body);
  }

  .annotation-note-label,
  .annotation-note-title {
    fill: var(--primary);
  }

  .annotation-note-bg {
    fill: rgba(0, 0, 0, 0.8);
    fill-opacity: 1;
  }

  .annotation-note-label,
  .annotation-note-title {
    font-size: var(--font-size-small);
  }

  d3fc-group.cartesian-chart {
    grid-template-columns: 1em auto 1fr;
    grid-template-rows: 0 auto 1fr 1em 2.5em;
  }

  .top-label,
  d3fc-group.cartesian-chart > .top-label {
    font-family: var(--font-header-family);
    font-size: var(--font-size);
    justify-self: start;
    margin-left: 0.8rem;
    color: var(--body);
    fill: var(--body);
  }

  .left-label,
  .right-label,
  .bottom-label,
  d3fc-group.cartesian-chart > .left-label,
  d3fc-group.cartesian-chart > .right-label,
  d3fc-group.cartesian-chart > .bottom-label {
    font-family: var(--font-header-family);
    font-size: var(--font-size-big);
    line-height: 1.3;
  }

  text.bar-value {
    fill: rgb(0, 0, 0, 0.6);
    stroke-width: 0;
    font-size: var(--font-size-tiny);
  }

  .app-palette-secondary text.bar-value,
  .app-palette-gray text.bar-value {
    fill: rgb(255, 255, 255, 0.4);
  }

  rect.line-value {
    fill: var(--bg-transparent);
  }

  .y2-bar,
  path.quantile-area {
    fill: var(--bg-2);
  }

  .y2-axis path.domain {
    opacity: 0.66;
  }

  .y2-label {
    display: flex;
    transform: rotate(90deg);
    width: 1em;
    white-space: nowrap;
    justify-content: center;
  }

  path.quantile-area.translucent {
    fill: var(--bg-accent-translucent);
  }

  svg.geo-chart {
    width: 100%;
    overflow: hidden;
    z-index: 0;
    border: 1px solid transparent;
    border-radius: var(--border-radius-big);
  }
}

.multi-chart .app-chart d3fc-group.cartesian-chart > .left-label,
.multi-chart .app-chart d3fc-group.cartesian-chart > .right-label,
.multi-chart .app-chart d3fc-group.cartesian-chart > .bottom-label {
  font-size: var(--font-size);
}

.app-chart-tiny-annotation, .app-chart {
  .annotation-note-label,
  .annotation-note-title {
    font-size: var(--font-size-tiny);
  }
}

.app-chart-no-annotation-connector .annotation-note .note-line {
  stroke: transparent;
}

.app-chart-hide-axis-lines {
  .y-axis,
  .x-axis {
    .tick path,
    path.domain {
      stroke: transparent;
    }
  }
}

.app-theme-light .app-chart {
  rect.quadrant {
    fill: rgba(0, 0, 0, 0.15);
  }

  .annotation-note-bg {
    fill: rgba(255, 255, 255, 0.8);
  }

  text.bar-value,
  rect.line-value {
    fill: rgb(0, 0, 0, 0.4);
  }

  .app-palette-body text.bar-value {
    fill: rgb(255, 255, 255, 0.6);
  }
}
